<template>
    <v-dialog
        persistent
        v-model="errorDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="100%"
        :max-width="'400px !important'"
    >
        <v-card>
            <v-card-title class="warning white--text justify-space-between">
                <span>{{ job + ' errors:' }}</span>
            </v-card-title>
            <v-card-text>
                <div class="d-flex flex-column mt-3">
                    <div v-for="error in errors" class="black--text text--darken-1">{{ '* ' + error }}</div>
                </div>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="errorDialog = false" >{{ $t('message.dismiss') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "ViewError",
    props: ['dialog','errors','job'],
    data() {
        return {
            error_dialog: false
        }
    },
    computed: {
        errorDialog: {
            get() {
                return this.error_dialog;
            },
            set(value){
                this.error_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {

    },
    watch: {
        dialog(value) {
            this.error_dialog = value
        }
    },
}
</script>

<style scoped>

</style>